import { StateCreator } from "zustand";

type SearchSessionIdSliceState = {
    /**
     * The Search Session ID for the current series of searches, if one is present.
     *
     * If a search request is made and an existing `ssid` is not provided, the search
     * response will contain one that should be stored here. Subsequent search requests
     * should include the stored `ssid`.
     *
     * The `ssid` should be cleared when the search session has reached its completion,
     * most commonly when a user clicks on a result.
     */
    ssid: string | null;
};

type SearchSessionIdSliceActions = {
    /** Set the Search Session ID. */
    setSsid: (ssid: string | null) => void;
};

export type SearchSessionIdSlice = SearchSessionIdSliceState & SearchSessionIdSliceActions;

const initialState: SearchSessionIdSliceState = {
    ssid: null,
};

export const createSearchSessionIdSlice: StateCreator<SearchSessionIdSlice> = (set) => ({
    ...initialState,
    setSsid: (ssid) => set({ ssid }),
});
